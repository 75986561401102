// ./
import dataformElements from './dataform.elements'
import { parseDataResponse, parseStackedDataResponse } from './response.parser'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { ActionField }    from '@/Helpers/Components/DataTable/ActionField'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { RutTools }       from '@/Classes/Static/RutTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader     from '@/Components/Global/BasicHeader/template.vue'
import DataTable       from '@/Components/Global/DataTable/template.vue'
import PopupUserForm   from '@/Components/Global/PopupUserForm/template.vue'
import StatusBar       from '@/Components/Global/StatusBar/template.vue'
import AdminNavigation from '@/Components/Modules/2/AdminNavigation/template.vue'
import PermissionsForm from '@/Components/Modules/2/PermissionsForm/template.vue'

// Components (Refs)
import { BasicHeaderRef }     from '@/Components/Global/BasicHeader/component'
import { DataFormRef }        from '@/Components/Global/DataForm/component'
import { DataTableRef }       from '@/Components/Global/DataTable/component'
import { PopupUserFormRef }   from '@/Components/Global/PopupUserForm/component'
import { StatusBarRef }       from '@/Components/Global/StatusBar/component'
import { PermissionsFormRef } from '@/Components/Modules/2/PermissionsForm/component'

// Constants
import { AppModules }  from '@/Constants/AppModules'
import { AppValues }   from '@/Constants/AppValues'
import { Breakpoints } from '@/Constants/Breakpoints'
import { Component }   from '@/Constants/Component'
import { Documents }   from '@/Constants/Documents'
import { Server }      from '@/Constants/Server'
import { VueRouter }   from '@/Constants/VueRouter'
import { Vuex }        from '@/Constants/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Helpers
import { InputPhone }  from '@/Helpers/Components/DataForm/InputPhone'
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View20 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Views.Modules.ADMIN_EXTERNAL_USERS.NAME,

	components: {
		AdminNavigation,
		BasicHeader,
		DataTable,
		PermissionsForm,
		PopupUserForm,
		StatusBar
	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseDataResponse,
				documentToUpdate: undefined as any,
				inputButtonKey: undefined as string,
				showDataTable: true,
				showPermissionsForm: false,
				showPopupUserForm: false,
				tempPermissions: undefined as any,
				userStorages: [] as Array<any>
			}
		}
	},

	beforeDestroy: function() {
		(Store.getters.getStoredAHFetchUsersForPage('External') as AxiosHelper)?.abortRequest()
	},

	mounted: async function() {
		this._initDataTable()
		this._initDataForm()
		this._initPermissions()

		// Deshabilitar comportamiento de Busqueda Local y Tipo de Descarga para el componente 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({
			exportDataAsAsync: true,
			exportDataFilterType: AppValues.DataTableExportDataFilterType.QUANTITY,
			isLocalSearch: false,
			preventDefaultStacked: true
		})

		// Llamar a la acción encargada de obtener las Regiones registradas.
		Store.dispatch('fetchRegions')

		// Componente PopupTable/DataTable.
		this._nestedDataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)

		// Component PopupUserForm/DataTable.
		this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true, showRefreshButton: false })
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataForm: function(): DataFormRef {
			return this._popupUserForm.$refs.dataForm as DataFormRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		},

		_permissionsForm: function(): PermissionsFormRef {
			return this.$refs.permissionsForm as PermissionsFormRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		},

		_statusBar: function(): StatusBarRef {
			return this.$refs.statusBar as StatusBarRef
		}
	},

	methods: {
		_initDataForm: function() {
			const { _dataForm } = this._popupUserForm
			_dataForm.initialize(dataformElements)
		},

		_initDataTable: async function(page = 1, forceRefresh = false) {
			this._dataTable.setFetchingState(true)
			this._statusBar.setStatus(true, 'Obteniendo listado de usuarios cliente...')
			this._statusBar.setElapsedTime(false)

			const sPerformance = performance.now()
			const response     = await Store.dispatch('fetchUsersForPage', { forceRefresh, isClient: true, page, type: 'External' })
			const ePerformance = performance.now()

			// Si se obtiene una respuesta válida, continuar con el proceso.
			if (response) {
				const { fields, items, actions } = this.states.dataTableParser(response.data)
				this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)

				this._dataTable.setFetchingState(false)
				this._statusBar.setStatus(false, `Resultado: Obtenido correctamente un total de ${ response.data.length } usuarios cliente`, 'success')
				this._statusBar.setElapsedTime(true, sPerformance, ePerformance)
			}
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_EXTERNAL_USERS?.privileges.write)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_EXTERNAL_USERS?.privileges.write)
		},

		_initPopupUserFormDataTable: function(storages: Array<any>, stackedOrDefault = false) {
			if (storages.length > 0) {
				// Acción para permitir remover la Ubicación de la Tabla.
				const _actions = [
					new ActionField('actions', 'Eliminar').addItem('delete', 'icon').setIcon('times-circle').setPermission('ACTION_DELETE').setVariant('red')
				]
				
				// Convertir los datos para mostrarlos en el <DataTable>.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupUserForm._dataTable.states.stacked || stackedOrDefault)
				const { fields, items, actions } = SummaryParser(storages, _actions)
				this._popupUserForm._dataTable.setElements(fields, items, actions)
				return
			}
			this._popupUserForm._dataTable.clearData()
		},

		_onAllFetchsAfterLoginCompleted: function() {
			this._initDataTable()
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })
			this._nestedDataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })
			this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })

			this.setStates<View20Ref['states']>({ dataTableParser: breakpoint <= Breakpoints.Large ? parseStackedDataResponse : parseDataResponse })
			this._initDataTable()
			this._updatePopupComponents(true)

			const { userStorages } = this.states
			this._initPopupUserFormDataTable(userStorages, breakpoint <= Breakpoints.Large)
		},

		_resolveUsersPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Users.AddUser
				: Server.Routes.Users.UpdateUser
		},

		_updateDataForm: function() {
			const { _dataForm } = this._popupUserForm
			const { documentToUpdate } = this.states

			// Eliminar valores no validos para el campo 'phone'.
			let filteredPhone = ''
			const _phone = documentToUpdate?.phone
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(_phone)) filteredPhone = _phone[0].toString().replace('+56 ', '')
			
			// Autocompletar los inputs al momento de editar.
			_dataForm.setValue('rut', documentToUpdate.rut)
			_dataForm.setValue('pLastName', documentToUpdate.pLastName)
			_dataForm.setValue('email', documentToUpdate.email)
			_dataForm.setValue('name', documentToUpdate.name)
			_dataForm.setValue('mLastName', documentToUpdate.mLastName)
			_dataForm.setValue('phone', filteredPhone)
			_dataForm.setValue('workarea', documentToUpdate._idWorkArea)
			_dataForm.setValue('isValid', documentToUpdate.isValid)

			// Autocompletar los InputsButtons al momento de editar.
			_dataForm.setValue('company', documentToUpdate._idCompany, '_id')
			_dataForm.setValue('company', documentToUpdate.companyName, 'value')
 
			// Si la fila contiene Ubicaciones, estás deben ser manipuladas desde un Array auxiliar para evitar errores de referencia.
			if (documentToUpdate.storages?.length > 0) {
				// const userStorages = documentToUpdate.storages.map((x: string) => Store.getters.getStoredStorageById(x))
				this.setStates<View20Ref['states']>({ userStorages: documentToUpdate.storages })
				this._initPopupUserFormDataTable(documentToUpdate.storages)
			}
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Validaciones Opcionales según Casos.
			const { _dataForm } = this._popupUserForm
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState(true)

			if (this.states.inputButtonKey === 'company') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selección Empresa')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyCompanies')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchCompaniesForPage', { forceRefresh, page })
				const SummaryParser = DataParsers.Companies.GetSummaryParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data.filter((x: any) => x.type))
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('name', 'asc')
			}
			else if (this.states.inputButtonKey === 'storage') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selección Ubicación')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyStorages')
				
				// Aplicar Registros con Paginación.
				const _idCompany = _dataForm.getValue('company', '_id')
				const response = await Store.dispatch('fetchStoragesByIdCompany', { _idCompany, page })
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data)
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('code', 'asc')
			}
		},

		onClose: function() {
			const { _dataForm, _dataTable } = this._popupUserForm
			this.setStates<View20Ref['states']>({ documentToUpdate: undefined, showPopupUserForm: false, userStorages: [] })
			_dataForm.clearInputs()
			_dataTable.clearData()
		},

		onDataFormSubmit: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate, userStorages } = this.states
			const { _dataForm } = this._popupUserForm
			const { action }    = _dataForm.states
			const isInserting   = action === Component.Actions.INSERT

			// Realizar validación de los Campos.
			if (!_dataForm.validateStates()) {
				this.showToast('Error de Validación', 'Verifica que ningun campo se encuentre marcado de color rojo.', 'danger')
				return
			}

			// Inputs del DataForm.
			const _idAdminCompany = Store.getters.getStoredUser._idAdminCompany
			const _idUser     = documentToUpdate?._idUser
			const _idRole     = Documents.Roles.Kreis.CLIENT
			const _idWorkArea = Documents.WorkAreas.Kreis.CLIENT
			const _idCompany  = _dataForm.getValue('company', '_id')
			const rut         = _dataForm.getValue<string>('rut').replace(/\./g, '')
			const name        = _dataForm.getValue('name')
			const email       = _dataForm.getValue<string>('email')?.toLowerCase()
			const pLastName   = _dataForm.getValue('pLastName')
			const mLastName   = _dataForm.getValue('mLastName')
			const phone       = [_dataForm.getElement<InputPhone>('phone').Value]
			const password    = _dataForm.getValue('password')
			const isValid     = _dataForm.getValue('isValid') === 'Sí'
			const storages    = userStorages.map((x: any) => x._idStorage || x._id)

			// Objeto con las Propiedades requeridas por la Petición.
			const body = isInserting ? {
				_idCompany, _idAdminCompany, _idRole, _idWorkArea, rut, name, email, pLastName, mLastName, phone, password, storages
			} : {
				_idUser, _idRole, _idWorkArea, rut, name, email, pLastName, mLastName, phone, isValid, storages
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? AppModules.Collections.JoiSchemas.Users.AddUser : AppModules.Collections.JoiSchemas.Users.UpdateUser
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear un' : 'Actualizar el' } Usuario Cliente`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = isInserting ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.fetchURL(fetchAction, this._resolveUsersPath(action), body)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				if (isInserting) {
					// Por Paginación, refrescar todos los Registros.
					this.onDTRefreshButtonClick()
					this.onClose()
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					// Por Paginación, refrescar todos los Registros.
					this.onDTRefreshButtonClick()
					this.onClose()
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}

			/////////////////////////////////////////////////////////////////////////////////////
			// PENDIENTE DE REFACTORIZAR
			/////////////////////////////////////////////////////////////////////////////////////
			else {
				const errorMessage = response.data.body.payload.message
				if (errorMessage.includes('rut')) {
					this.showToast('Error actualización', 'El Rut ya ha sido ingresado', 'danger')
				}
				else if (errorMessage.includes('email')) {
					this.showToast('Error actualización', 'El Email ya ha sido ingresado', 'danger')
				}
			}
		},

		onDTCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onDTSearchButtonClicked(searchParams, page)
		},

		onDTButtonClick: function(key: string, row: any) {
			if (key === 'edit') {
				this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })
				this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ newButtonDisabled: false })
				this.setStates<View20Ref['states']>({ dataFormAction: Component.Actions.UPDATE, documentToUpdate: row.item, showPopupUserForm: true })
				this._updateDataForm()
			}
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.QuantityChoiceList) {
			// Datos requeridos para obtener los Registros a Descargar.
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Objeto con las Propiedades requeridas por la Petición.
			const params = {
				_idAdminCompany,
				isClient: true,
				itemsPerPage: 1,
				page: 1,
				quantity: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states

			// Realizar la Petición al servidor.
			const response = await this.fetchURL(Server.Fetching.Method.GET, Server.Routes.Users.GetUsersByPage, params)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body.data
				const { items } = dataTableParser(_response)
				this._dataTable.handleExportDataType(format, items)
			}
		},

		onDTNewButtonClick: function() {
			this.setStates<View20Ref['states']>({ dataFormAction: Component.Actions.INSERT, showPopupUserForm: true })
			this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: this.states.dataFormAction })
			this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ newButtonDisabled: true })
		},

		onDTPaginationChanged: async function(page: number) {
			this._initDataTable(page)
		},

		onDTRefreshButtonClick: async function() {
			Store.commit('destroyExternalUsers')
			this._initDataTable(1, true)
		},

		onDTSearchButtonClicked: function(searchParams: any, page = 1) {
			let { searchKey, searchValue } = searchParams
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Campo Rut.
			let isRutValid = null as boolean
			if (searchKey === 'rut') isRutValid = RutTools.validator(searchValue)

			// Campo Fono.
			if (searchKey === 'phone') searchValue = searchValue.replace(' ', '')

			// Realizar Petición de Busqueda (y Realizar Formateo con Parser).
			this._dataTable.doInputSearch(Server.Routes.Users.GetUsersBySearchFilter,
				this.states.dataTableParser,
				{
					_idAdminCompany, isClient: true,
					itemsPerPage: this._dataTable.itemsPerPage, page,
					searchKey, searchValue: isRutValid ? RutTools.formatter(searchValue) : searchValue
				},
				(response) =>  response.data.body[0]
			)
		},

		onInputButtonClick: function(key: string) {
			this.setStates<View20Ref['states']>({ inputButtonKey: key })
			this._updatePopupComponents(false)
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { item } = currentRow
			const { _dataForm, _dataTable } = this._popupUserForm

			if (key === 'company') {
				_dataForm.setValue(key, currentRow.item._idCompany, '_id')
				_dataForm.setValue(key, currentRow.item.name, 'value')
				_dataTable.setStates<DataTableRef['states']>({ newButtonDisabled: false })
			}
			else if (key === 'storage') {
				// Eliminar Propiedades que no prestan función.
				delete item.$region
				
				// Validar que no se ingresen multiples copias de una misma ubicación.
				const { userStorages } = this.states
				const found = userStorages.find((x: any) => (x._idStorage === item._idStorage) || (x._id === item._idStorage))
				if (found) return this.showToast('Ubicación ya ingresada', `La ubicación ${ item.code } ya se encuentra ingresada en la tabla!`, 'warning')
				
				// Agregar la fila al Array del objeto.
				userStorages.push(item)
				this._initPopupUserFormDataTable(userStorages)
			}

			// Forzar cierre del componente 'PopupTable'.
			_dataForm.setStates<DataFormRef['states']>({ showPopupTable: false })
		},

		onPUFButtonClick: function(key: string, row: any) {
			if (key === 'delete') {
				const { index } = row
				const { userStorages } = this.states
				userStorages.splice(index, 1)
				this._initPopupUserFormDataTable(userStorages)
			}
		},

		onPUFCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onPUFSearchButtonClicked(searchParams, page)
		},

		onPUFNewButtonClick: function() {
			this.setStates<View20Ref['states']>({ inputButtonKey: 'storage' })
			this._updatePopupComponents(false)
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function() {
			this._updatePopupComponents(false, 1, true)
		},

		onPUFSearchButtonClicked: function(searchParams: any, page = 1) {
			// Parametros.
			const { _idAdminCompany }        = Store.getters.getStoredUser
			const { inputButtonKey }         = this.states
			const { _isStacked }             = this._nestedDataTable

			// Cambio de Estado de Carga.
			this._nestedDataTable.setFetchingState(true)

			// Endpoints y Parser.
			const summaryParser = inputButtonKey === 'company' ? DataParsers.Storages.GetSummaryParser(_isStacked) : DataParsers.Storages.GetSummaryParser(_isStacked)
			const endpoint      = inputButtonKey === 'company' ? Server.Routes.Companies.GetCompaniesBySearchFilter : Server.Routes.Storages.GetStoragesBySearchFilter

			// Parametros requeridos por los 'Endpoints'.
			const params = {
				_idAdminCompany,
				isClient:     true,
				itemsPerPage: this._nestedDataTable.itemsPerPage,
				page,
				searchKey:    searchParams.searchKey,
				searchValue:  searchParams.searchValue
			}

			// Para el caso de las 'Plantas', eliminar propiedad 'isClient'.
			if (inputButtonKey === 'storage') delete params.isClient

			// Ejecución de Endpoint Buscar.
			this._nestedDataTable.doInputSearch(endpoint, summaryParser, params, (response) =>  response.data.body[0])
		},

		onServerCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, error: any) {
			// Controlar las excepciones de las diferentes peticiones realizadas en la vista.
			if (path === Server.Routes.Storages.GetStorages) {
				this.showToast('Sin Registros', 'No existen Ubicaciones para mostrar!', 'danger')
			}
			else if (path === Server.Routes.Users.AddUser) {
				
			}
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onServerFailedResponse: function(path: string) {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Users, '_initDataTable')
	}
})

// Exports
export default View20
export type View20Ref = InstanceType<typeof View20>