// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Parser Exports
export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea', 'name', 'pLastName', 'mLastName', 'storages', 'roleName', 'workAreaName', 'permissions', 'codeSAP', 'codeBranch', 'nameBranch', 'originSAP', 'systemValid')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('rut', 'Rut').ignoreSpecialChars().isSortable().setAlign('center', 'center')
		.add('mName', 'Nombre').isSortable().setSearchKey('user.name')
		.add('email', 'Correo').isSortable()
		.add('phone', 'Fono').isSortable()
		.add('companyName', 'Cliente').isSortable().setSearchKey('company.name')
		.add('isValid', '¿Esta Activo?').setAlign('center', 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('rut', 'mName', 'email', 'phone', 'companyName', 'storagesName', 'isValid')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('companyName')
		.add('mName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		mName: (v: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		phone: (values: Array<string> | string) => {
			if (Array.isArray(values)) return PrimitiveTools.Arrays.isInvalidOrEmpty(values) ? AppValues.Strings.DEFAULT_EMPTY_STRING : values
			if (typeof values === 'string') if (values !== '') return [values]
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea', 'email', 'name', 'pLastName', 'phone', 'mLastName', 'roleName', 'workAreaName', 'permissions', 'companyName', 'storages', 'isValid', 'codeSAP', 'codeBranch', 'nameBranch', 'originSAP', 'systemValid')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('rut', 'Rut').ignoreSpecialChars().isSortable().setAlign('center', 'center').showDetailsButton()
		.add('mName', 'Nombre').isSortable().setSearchKey('user.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('rut', 'mName')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('mName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		mName: (_: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Correo', 'email')]: ({ email }) => {
			return email
		},
		[RecordHelper.setFlags('Fono', 'phone')]: ({ phone }) => {
			return PrimitiveTools.Arrays.isInvalidOrEmpty(phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : phone
		},
		[RecordHelper.setFlags('Cliente', 'companyName', 'company.name')]: ({ companyName }) => {
			return companyName
		},
		[RecordHelper.setFlags('¿Esta Activo?', 'isValid')]: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}